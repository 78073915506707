.login-main-cont
{
	width: 100%;
	height: 100vh;
	background-color: aquamarine;
	position: absolute;
	background-image: url(./assets/bg.jpg);
	background-position: left center;
	background-size: cover;
	background-repeat: no-repeat;
}

.login-cont
{
    margin-top: 16vh;
    margin-left: auto;
    margin-right: auto;
	width: 500px;
	max-width: 80%;
    text-align: center;
}


.credit a
{
	color: #b2b2b2;
}

.title
{
	color: white;
    font-size: 60px;
    margin-bottom: 6vh;
    font-family: "Rocket";
}

.form-cont
{
    background-color: white;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
}

form > input:not(.login-btn) {
    width: 100%;
    margin-bottom: 20px;
    border: none;
    padding: 5px;
    padding-bottom: 0px;
    box-sizing: border-box;
    border-bottom: 1px solid #aeaeae;
    font-size: 16px;
}


.errors:not(:empty) {
	color: red;
	margin: 10px 0px;
	font-weight: 400;
}

.errors:not(:empty):before
{
	content: "Error";
	display: block;
}

.footer
{
	padding: 3px 10px;
	width: 100%;
	position: absolute;
	bottom: 0px;
	left: 0px;
	font-size: 12px;
	line-height: 14px;
	color: white;
	background-color: rgba(0, 0, 0, 0.33);
}
