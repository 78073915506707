.positions-cont {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% + 5px);
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	margin-left: -8px;
	color: var(--font-color);
}

/*
** -- Take Position Modal
*/

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 50px;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(3px);
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-cont {
	background-color: var(--background-color-secondary);
	padding: 15px;
	border-radius: 20px;
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 black;
}

.modal-cont input[type="number"] {
	background-color: var(--background-color);
	padding: 3px;
}
