* {
	--color-primary: #d02a10;
	--background-color: #FFFFFF;
	--background-color-darken: #eaeaea;
	--background-color-rgb: 242, 242, 244;
	--background-color-secondary: #f3f2f5;
	--background-color-secondary-rgb: 255, 255, 255;
	--main-menu-bg: white;
	--main-menu-shadow: #cbcbcb;
	--main-menu-nav-links-cont-bg: #F2F2F4;
	--main-menu-nav-links-btn-bg: white;
	--tiles-bg-color: #F2F2F4;
	--font-color: black;
	--input-color: #FFFFFF;
	--input-color-darken: rgb(200, 200, 200);
	--invert: 0;
}

.theme-dark * {
	--background-color: #1f1f1f;
	--background-color-rgb: 44, 44, 44;
	--background-color-darken: #323232;
	--background-color-secondary: #2c2c2c;
	--background-color-secondary-rgb: 31, 31, 31;
	--main-menu-bg: #2c2c2c;
	--main-menu-shadow: #202020;
	--main-menu-nav-links-cont-bg: #1f1f1f;
	--main-menu-nav-links-btn-bg: #2c2c2c;
	--tiles-bg-color: #2c2c2c;
	--font-color: white;
	--input-color: #1c1c1c;
	--input-color-darken: #1c1c1c;
	--invert: 1;
}

body
{
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	font-family: "Roboto";
	font-weight: 300;
	background-color: var(--background-color);
}

.App {
	height: 100vh;
	color: var(--font-color);
}

.wrapper {
    height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: var(--background-color);
	color: var(--font-color);
}

.body-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: auto;
	overflow: hidden;
	margin: 3vh auto;
	padding: 0px 10px;
}

@media screen and (max-width: 650px) {

	.body-wrapper
	{
		width: 100%;
	}
}

@media screen and (min-width: 650px) and (max-width: 1000px) {
	.body-wrapper
	{
		width: 90%;
	}
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
	.body-wrapper
	{
		width: 80%;
	}
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
	.body-wrapper
	{
		width: 80%;
	}
}

@media screen and (min-width: 1400px) and (max-width: 2000px) {
	.body-wrapper
	{
		width: 70%;
	}
}

@media screen and (min-width: 2000px) {
	.body-wrapper
	{
		width: 70%;
	}
}

/*
** -- disabled
*/

.disabled,
.disabled * *,
.disabled * * input,
input:disabled  {
	pointer-events: visible;
	cursor: not-allowed;
}

.updating-cont {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: rgba(0, 0, 0, 0.10);
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: visible;
	cursor: wait;
	display: none;
}

.updating .updating-cont {
	display: block;
}

/*
** -- price
*/

.color-green
{
	color: green;
}

.color-red
{
	color: red;
}

.market-variation {
	font-size: 10px;
}

/*
** -- btns
*/


.btn {
    display: inline-block;
    width: 100%;
	border: none;
    border-radius: 5px;
    background-color: white;
    background-color: var(--background-color-secondary);
	color: var(--font-color);
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 20px;
    font-size: 15px;
    cursor: pointer;
    vertical-align: middle;
}

.btn:hover {
	color: var(--font-color);
}

.btn-red {
	background-color: #FF0A18;
	color: white;
}

.btn-blue {
	background-color: #0080B9;
	color: white;
}

.btn-grey {
	background-color: #C1BFC1;
	color: white;
}

.btn-green {
	background-color: rgb(0, 185, 83);
	color: white;
}

.btn-yellow {
	background-color: #ffd300;
	color: white;
}

.btn-primary {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}


/*
** -- errors cont
*/

.errors-cont {
	font-weight: 400;
    background-color: #c5030b;
	color: white;
    padding: 5px;
    border-radius: 6px;
	position: relative;
	line-height: 18px;
	font-size: 15px;
}

.errors-cont.closable {
	padding-top: 15px;
}

.errors-cont:empty
{
	display: none;
}

.close-errors-btn {
	position: absolute;
	font-size: 12px;
	left: 5px;
	top: 0px;
}

/*
** -- Overload
*/

a:hover {
	color: unset
}

.cursor-pointer
{
	cursor: pointer;
}

input[type="number"] {
	border: none;
	border-radius: 3px;
	background-color: var(--input-color);
	color: var(--font-color);
	display: inline-block;
	padding: 5px;
}


/*
**	-- ScrollBar
*/

::-webkit-scrollbar {
	width: 4px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background: #e0e0e0a1;
	background: var(--color-primary);
}

::-webkit-scrollbar-corner {
	background: transparent;
}

/*
** -- Animations
*/

@keyframes spinner {
	0% { transform: rotate(0deg);}
	100% { transform: rotate(360deg);}
}


/*
** -- DEBUG
*/

.box-border
{
	border: 1px solid red; 
}

.nav-link *{
	/*border: 1px solid red;*/
}

