.text-thin
{
	font-family:'SF-Text-Thin';
}

.text-ulight
{
	font-family:'SF-Text-ULight';
}

.text-light
{
	font-family:'SF-Text-Light';
}

.text-regular
{
	font-family:'SF-Text-Regular';
}

.text-semibold
{
	font-family:'SF-Text-SemiBold';
}

.text-bold
{
	font-family:'SF-Text-Bold';
}

.text-heavy
{
	font-family:'SF-Text-Heavy';
}

.text-italic
{
	font-family:'SF-Text-Italic';
}

.disp-thin
{
	font-family:'SF-Disp-Thin';
}

.disp-ulight
{
	font-family:'SF-Disp-ULight';
}

.disp-light
{
	font-family:'SF-Disp-light';
}

.disp-regular
{
	font-family:'SF-Disp-Regular';
}

.disp-semibold
{
	font-family:'SF-Disp-SemiBold';
}

.disp-bold
{
	font-family:'SF-Disp-Bold';
}

.disp-heavy
{
	font-family:'SF-Disp-Heavy';
}

.disp-italic
{
	font-family:'SF-Disp-Italic';
}

/*
** -- SF-Disp
*/

@font-face {
	font-family:'SF-Disp-Thin';
	src:url(fonts/SF-Pro-Display/SF-Pro-Display-Thin.otf);
}

@font-face {
	font-family:'SF-Disp-ULight';
	src:url(fonts/SF-Pro-Display/SF-Pro-Display-Ultralight.otf);
}

@font-face {
	font-family:'SF-Disp-Light';
	src:url(fonts/SF-Pro-Display/SF-Pro-Display-Light.otf);
}

@font-face {
	font-family:'SF-Disp-Regular';
	src:url(fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf);
}

@font-face {
	font-family:'SF-Disp-SemiBold';
	src:url(fonts/SF-Pro-Display/SF-Pro-Display-Semibold.otf);
}

@font-face {
	font-family:'SF-Disp-Bold';
	src:url(fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf);
}

@font-face {
	font-family:'SF-Disp-Heavy';
	src:url(fonts/SF-Pro-Display/SF-Pro-Display-Heavy.otf);
}

@font-face {
	font-family:'SF-Disp-Italic';
	src:url(fonts/SF-Pro-Display/SF-Pro-Display-RegularItalic.otf);
}

/*
** -- SF-Text
*/

@font-face {
	font-family:'SF-Text-Thin';
	src:url(fonts/SF-Pro-Text/SF-Pro-Text-Thin.otf);
}

@font-face {
	font-family:'SF-Text-ULight';
	src:url(fonts/SF-Pro-Text/SF-Pro-Text-Ultralight.otf);
}

@font-face {
	font-family:'SF-Text-Light';
	src:url(fonts/SF-Pro-Text/SF-Pro-Text-Light.otf);
}

@font-face {
	font-family:'SF-Text-Regular';
	src:url(fonts/SF-Pro-Text/SF-Pro-Text-Regular.otf);
}

@font-face {
	font-family:'SF-Text-SemiBold';
	src:url(fonts/SF-Pro-Text/SF-Pro-Text-Semibold.otf);
}

@font-face {
	font-family:'SF-Text-Bold';
	src:url(fonts/SF-Pro-Text/SF-Pro-Text-Bold.otf);
}

@font-face {
	font-family:'SF-Text-Heavy';
	src:url(fonts/SF-Pro-Text/SF-Pro-Text-Heavy.otf);
}

@font-face {
	font-family:'SF-Text-Italic';
	src:url(fonts/SF-Pro-Text/SF-Pro-Text-RegularItalic.otf);
}

