.search-cont {
	display: flex;
	color: white;
	transition-duration: 0.3s;
	justify-content: center;
	flex-basis: 0;
	flex-grow: 1;
	align-items: center;
	margin: 0px 3%;
	max-width: 400px;
	position: relative;
}

.search-cont input {
	width: 0%;
    font-size: 14px;
	font-family: "Roboto";
	font-weight: 300;
	letter-spacing: 1px;
    padding: 0;
    border: 0px;
    background-color: transparent;
	display: block;
	transition-duration: 0.3s;
	color: white;
}

.search-cont .search-btn {
	background-color: transparent;
	border: none;
	color: white
}

.search-btn
{
    background-image: url(./search-icon.png);
    background-size: auto 90%;
    background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	padding: 10px;
	margin-bottom: 0px;
}

.search-cont.load .search-btn
{
	background-image: url(./spinner.png);
	animation: spinner 1.2s ease-in-out infinite;
	background-position: center 2px;
}

.search-cont.open
{
	border-bottom: 1px solid white;
}

.search-cont.open input
{
	padding: 5px;
	width: 100%;
	padding-right: 20px;
	transition-duration: 0.8s;
	margin-right: 5px;
}

.search-cont input::placeholder {
	color: white;
  }

.search-cont.open .search-icon
{
	margin-bottom: 3px;
}

.clear-search-bar {
	background-color: rgba(0, 0, 0, 0.168);
	line-height: 0;
	width: 13px;
	height: 13px;
	font-size: 10px;
	border-radius: 50px;
	display: none;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
	position: absolute;
	top: 9px;
	right: 30px;
	cursor: pointer;
}

.search-cont.open .clear-search-bar {
	display: flex;
}
