.head-bar
{
	width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
    box-sizing: border-box;
    align-items: center;
    box-shadow: 0 1px 2px 1px #00000033;
    /* height: 40px; */
    background-color: #de4949;
    color: white;
    background: linear-gradient(90deg, #d02a10, #de4949);
    color: white;
	z-index: 3;
	position: relative;
}

.head-title
{
	font-family: "Rocket";
    font-size: 28px;
	font-weight: 600;
	letter-spacing: -4px;
    word-spacing: -6px;
}

.btn-disconnect
{
	font-family: "Roboto";
	font-weight: 300;
	cursor: pointer;
}

.head-bar-menu {
	position: fixed;
	top: 47px;
	right: -100%;
	background-color: var(--background-color);
	color: var(--font-color);
	padding: 10px;
	border-bottom-left-radius: 6px;
	box-shadow: 0px 0px 2px 1px #00000033;
	transition-duration: 0.5s;
	z-index: 1;
}

.head-bar-menu.open {
	right: 0px;
	flex-direction: column;
}

.head-bar-menu-icon
{
	transition-duration: 0.5s;
}

.head-bar-menu-icon.open {
	transform: rotate(180deg);
}

.search-results-cont {
	position: absolute;
	padding-top: 47px;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 2;
	height: 0px;
	pointer-events: none;
}

.search-results {
	max-width: 100%;
	border-radius: 0px 0px 10px 10px;
	background-color: var(--background-color);
	color: var(--font-color);
	box-shadow: 0 0 12px 0px #0000004d;
	padding: 10px;
	display: table;
	height: 100%;
	pointer-events: all;
}

.search-results:empty {
	display: none;
}

.search-result {
	font-weight: 500;
	font-size: 14px;
	overflow: hidden;
}

.search-result:not(:last-child) {
	border-bottom: 1px solid var(--background-color-darken);
}

.search-result td {
	padding: 3px 5px;
}

.search-result:hover td {
	background-color: var(--background-color-darken);
	cursor: pointer;
}

.search-result td:first-child {
	padding-right: 10px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.search-result td:last-child {
	padding-right: 10px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.search-result td:not(:last-child)
{
	padding-right: 10px;
}

.search-result .price
{
	text-align: right;
}

.search-result .sell-price {
	color: red;
}

.search-result .buy-price {
	color: green;
}

.search-result>.fav-icon {
	color: rgb(255, 183, 0);
	opacity: 0;
}

.fav-icon.saved,
.search-result:hover>.fav-icon {
	opacity: 1;
}

