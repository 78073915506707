.position-tile {
	background-color: #F2F2F4;
	background-color: var(--tiles-bg-color);
	border-radius: 6px;
    width: 400px;
    padding: 15px;
    margin: 8px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
	width: calc(20% - 16px);
	transition-duration: 1s;
	font-size: 14px;
	color: var(--font-color);
	position: relative;
	overflow: hidden;
}

.pos-updating-cont {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: rgba(0, 0, 0, 0.10);
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: visible;
	cursor: wait;
	display: none;
}

.position-tile.updating .pos-updating-cont {
	display: block;
}

.pos-head-cont {
	display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--background-color);
    padding-bottom: 2px;
    margin-bottom: 12px;
}

.market-mid {
	font-size: 10px;
	line-height: 10px;
	text-align: right;
	min-width: max-content;
}

.close-pos-btn {
	opacity: 0;
	transition-duration: 0.2s;
	cursor: pointer;
	position: absolute;
	top: 3px;
	left: 15px;
	color: red;
}

.position-tile:not(.updating):hover .close-pos-btn {
	opacity: 1;
	transition-duration: 0.5s;
}

.pos-body {
	font-size: 12px;
}

.pos-body > * {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pos-body > *:not(:last-child) {
	margin-bottom: 10px;
}

.position-tile .instrument-name {
	font-size: 12px;
	line-height: 14px;
	word-break: keep-all;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.position-tile input[type="number"] {
	background-color: var(--input-color);
	color: var(--font-color);
	text-align: right;
	padding: 0px;
	width: 40px;
}

.position-tile input.sl-input {
	width: 70px;
}

.pos-open-level {
	font-weight: 600;
}

.pos-currency {
	font-size: 8px;
}

.reducer-cont {
	background-color: var(--background-color);
	border-radius: 6px;
	overflow: hidden;
}

.reducer-cont.reducing:after {
	content: "";
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 24px;
	background-image: url(/assets/pictos/spinner.png);
	background-position: center;
	background-size: auto 15px;
	background-repeat: no-repeat;
	animation: spinner 1.5s infinite ease-in-out;
	transform-origin: center;
}


.reducer-cont.reducing .reduce-btn {
	display: none;
}

.reduce-btn
{
	padding: 3px 4px;
	width: 100%;
	background-color: var(--background-color);
	color: grey;
	border-radius: 0px;
	border: none;
}

.reduce-btn:not(:first-child)
{
	border-left: 1px solid var(--tiles-bg-color);
}

.reduce-btn:hover
{
	background-color: var(--color-primary);
	color:white;
	filter: brightness(0.93);
}

@media screen and (max-width: 650px) {
	.position-tile
	{
		width: 100%
	}
}

@media screen and (min-width: 650px) and (max-width: 1000px) {
	.position-tile
	{
		width: calc(50% - 16px);
	}
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
	.position-tile
	{
		width: calc(33% - 16px);
	}
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
	.position-tile
	{
		width: calc(33% - 16px);
	}
}

@media screen and (min-width: 1400px) and (max-width: 2000px) {
	.position-tile
	{
		width: calc(25% - 16px);
	}
}

@media screen and (min-width: 2000px) {
	.position-tile
	{
		width: calc(20% - 16px);
	}
}
