.switch {
	position: relative;
	box-sizing: border-box;
	height: 18px;
	cursor: pointer;
	width: 40px;
	margin-left: 0px;
	-moz-appearance: initial;
	background-color: transparent;
	z-index: 0;
}

.switch::before {
	background-color: #b6b6b6;
	width: 100%;
	display: block;
	content: " ";
	height: 100%;
	border-radius: 20px;
	z-index: 1;
	position: relative;
	transition-duration: 0.5s;
}

.switch::after {
    background-color: #00000060;
    content: " ";
    display: block;
    width: 16px;
    height: 16px;
    z-index: 2;
    position: absolute;
    border-radius: 50%;
    top: 1px;
    left: 2px;
    transition-duration: 0.5s;
}

.switch:checked::before {
	background-color: #2eba48;
}

.switch.primary:checked::before {
	background-color: var(--color-primary);
}

.switch:checked::after {
	left: calc(100% - 18px);
}

.switch:checked.load::before
{
	background-color: rgb(182, 182, 182);
}

.switch:checked.load:after
{
	animation: load 4s infinite linear;
    background-image: url(../assets/spinner-red.png);
    background-size: 75%;
    background-position: center;
	background-repeat: no-repeat;
	background-color: white;
}


.switch:checked.wait:before
{
	background-color: rgb(0, 137, 179);
}

@keyframes load {
	0%   { transform: rotate(0deg);}
	100%   { transform: rotate(360deg);}
}
