
.top-bar
{
	width: 100%;
	background-color: var(--background-color-secondary);
	border-radius: 6px;
	padding: 0px 3px;
	margin-bottom: 20px;
	color: var(--font-color);
}

.top-bar .markets-list
{
	display: flex;
	max-width: 100%;
	overflow-y: hidden;
	overflow-x: auto;
	padding: 2px;
	width: 100%;
}

.top-bar .markets-list > .top-bar-item {
	background-color: var(--background-color-darken);
	border-radius: 6px;
	padding: 6px;
	position: relative;
}

.top-bar .markets-list > .top-bar-item.updating .updating-cont {
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 6px;
}
.theme-dark .top-bar .markets-list > .top-bar-item.updating .updating-cont{
	background-color: rgba(0, 0, 0, 0.3);
}

.top-bar-item {
	margin: 3px 0px;
	color: var(--font-color);
}

.top-bar-item:not(:last-child) {
	margin-right: 15px;
}

.top-bar .prices
{
	background-color: #3D3D45;
	color: white;
	border-radius: 6px;
	font-size: 14px;
	line-height: 16px;
	overflow: hidden;
	cursor: pointer;
}

.top-bar .prices .sell-btn:hover
{
	transition-duration: 0.2s;
	background-color: #b50000;
}

.top-bar .prices .buy-btn:hover
{
	transition-duration: 0.2s;
	background-color: green;
}

.top-bar .arrows
{
	background-color: var(--background-color-darken);
	border-radius: 0px 3px 3px 0px;
	margin-right: -3px;
}

.top-bar .arrows > div
{
	cursor: pointer;
}

.top-bar .arrows > div::after
{
	background-image: url(./assets/down-arrow.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	content: "";
	width: 10px;
	height: 10px;
	transform: rotate(90deg);
}

.theme-dark .top-bar .arrows > div::after {
	filter: invert(var(--invert));
}

.top-bar .arrows > div.next::after
{
	transform: rotate(-90deg);
}

.h-sep
{
	width: 1px;
	background-color: #797979;
}

.top-bar input {
	height: 22px;
	width: 70px;
	margin: 0px 3px;
}

.top-bar .instrument-name {
	font-size: 12px;
	line-height: 14px;
}

.top-bar .no-market{
	color: grey;
}
